import React from 'react'
import './../Tooltip/tooltip.css'
export default function StatusToolTiptext ({ StatusToolTip }) {
  return (
    <>
      <div className='tooltip-status-main-div'>
        <div>{StatusToolTip[0]}</div>
        <div>{StatusToolTip[1]}<br />
          <ul>
            <li>{StatusToolTip[2]}</li>
            <li>{StatusToolTip[3]}</li>
            <li>{StatusToolTip[4]}</li>
          </ul>
        </div>
        <div>{StatusToolTip[5]}
          <ul>
            <li>{StatusToolTip[6]}</li>
            <li>{StatusToolTip[7]}</li>
          </ul>
        </div>
      </div>
    </>
  )
}
