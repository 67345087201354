import React, { useState, useRef, useContext } from 'react'
import ReactTable from 'react-table'
import './react-table-bootstrap.css'
import dompurify from 'dompurify'
import SearchBox, { getInitialSearchValues } from '../SearchBox'
import InfoTabs from '../InfoTabs'
import ModalSection from '../ModalSection/ModalSection'
import getTableColumns from './tableColumns'
import ReactTableFormattablePagination from '../TableComponents/react-table-formattable-pagination'
import { StringContext } from '../../StringContext'
import { PageMethodsContext } from '../../PageMethodsContext'

const ROWS_PER_PAGE = 20

/*
          fetchMethod={getCompanyCards}
          customerSearchMethod={customerSearch}
          validateCardMethod={validateCard}
          addCardMethod={registerCompanyCardOnCustomer}
          handleRequestCardExchange={requestCardExchange}
          handleRequestCardDelete={requestCardDelete}
*/

export default function CardHotelOverview ({ featureToggles = {}, CardHotelOverviewRoot }) {
  const tableRef = useRef(null)
  const localizedStrings = useContext(StringContext)
  const pageMethods = useContext(PageMethodsContext)

  /**
   * State hooks section
   */
  const [data, setData] = useState({ CompanyCards: [], Limit: ROWS_PER_PAGE, Offset: 0, TotalCount: 0, ShippingAddress: '' })
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [exchangeCardModalChipNumber, setExchangeCardModalChipNumber] = useState(false)
  const [deleteCardModalChipNumber, setDeleteCardModalChipNumber] = useState(false)
  const urlParams = new URLSearchParams(window.location.search)
  const [searchBoxValues, setSearchBoxValues] = useState(getInitialSearchValues({ vat: urlParams.get('vat'), rowsPerPage: ROWS_PER_PAGE }))
  const sanitizer = dompurify.sanitize
  const tableStrings = localizedStrings.TableStrings
  const registerStrings = localizedStrings.RegisterTab
  const ManualInfo = () => {
    // Add language method
    const language = 'en-GB'
    const finalText = registerStrings.RegisterTabLine3.replace('{0}', '<a href="../Manuals/CompanyCardReader/CompanyCardReader_manual_' + language + '.pdf">' + registerStrings.ScaniaCardManual + '</a>')
    return <span dangerouslySetInnerHTML={{ __html: sanitizer(finalText) }} />
  }
  const RegisterInfo = () => {
    const finalText = registerStrings.RegisterTabLine2.replace('{0}', '<a href="../CompanyCardReader/CompanyCardReader_Setup.zip">' + registerStrings.ScaniaCardReader + '</a>')
    return <span dangerouslySetInnerHTML={{ __html: sanitizer(finalText) }} />
  }

  const tabDeclarations = ({ toggleNewCard }) => {
    const tabs = []
    if (toggleNewCard) {
      tabs.push(
        {
          tabName: registerStrings.TabName,
          tabContent: () => {
            return (
              <div>
                <p>{registerStrings.RegisterTabLine1}</p>
                <p><RegisterInfo /></p>
                <p><ManualInfo /></p>
              </div>
            )
          }
        }
      )
    }
    return tabs
  }

  function onSortedChange (newSorted, column, shiftKey) {
    console.log('onSortedChange', newSorted, column, shiftKey)
    setCurrentPage(0)
    tableRef.current.state.page = 0
  }

  function onPageChange (page) {
    console.log('onPageChange(' + page + ')')
    setCurrentPage(page)
  }

  function onFetchData (state, instance) {
    const userChangedPage = () => state.page !== currentPage
    const getNewPage = () => (userChangedPage() ? state.page : 0)
    const calculateOffset = () => getNewPage() * state.pageSize

    console.log('state.page:', state.page)
    console.log('current page: ', currentPage)
    const search = Object.assign({}, searchBoxValues)
    search.offset = calculateOffset()
    if (state.sorted && Array.isArray(state.sorted) && state.sorted.length) {
      search.orderBy = state.sorted[0]
    }
    search.limit = state.pageSize
    setSearchBoxValues(search)
    fetchData(search)
  }

  function fetchData (searchValues) {
    const makeNullIfAll = value =>
      value && value.toLowerCase && value.toLowerCase() === 'all'
        ? null
        : value

    setLoading(true)
    searchValues.expiredStatus = makeNullIfAll(searchValues.expiredStatus)
    searchValues.status = makeNullIfAll(searchValues.status)
    console.log('Search sent to server: ', { searchValues })
    pageMethods.getCompanyCards(searchValues)
      .then(data => {
        data = JSON.parse(data)
        if (typeof data.d === 'string') {
          data = JSON.parse(data.d)
        }
        console.log('From the server: ', data)
        setData(data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        if (error.message.includes("'PageMethods'")) {
          console.warn(error.message)
        } else {
          console.error(error)
        }
      })
  }

  function onSearchSubmit (event) {
    event.preventDefault()
    const search = Object.assign({}, searchBoxValues)
    search.offset = 0
    setSearchBoxValues(search)
    setCurrentPage(0)
    tableRef.current.state.page = 0
    fetchData(search)
  }

  function clearSearchFilters () {
    setSearchBoxValues(getInitialSearchValues({ vat: '', rowsPerPage: ROWS_PER_PAGE }))
  }

  function searchBoxChangeHandler ({ control, value }) {
    const values = Object.assign({}, searchBoxValues)
    values[control] = value
    setSearchBoxValues(values)
  }

  function onExchangeModalClose (event, statusMessage) {
    if (statusMessage.messageType === 'success') {
      onSearchSubmit({ preventDefault: () => {} })
    }
    setExchangeCardModalChipNumber(false)
  }

  function onDeleteModalClose (event, statusMessage) {
    if (statusMessage.messageType === 'success') {
      onSearchSubmit({ preventDefault: () => {} })
    }
    setDeleteCardModalChipNumber(false)
  }

  return (
    <>
      <section>
        <InfoTabs tabDeclarations={tabDeclarations(featureToggles)} />
      </section>
      <section>
        <SearchBox
          handleSubmit={onSearchSubmit}
          changeHandler={searchBoxChangeHandler}
          handleClearSearchFilters={clearSearchFilters}
          localizedStrings={localizedStrings}
          {...searchBoxValues}
        />
        <section>
          <ReactTable
            PaginationComponent={ReactTableFormattablePagination}
            ref={tableRef}
            className='-striped'
            data={data.CompanyCards}
            columns={getTableColumns({ localizedStrings, featureToggles, setExchangeCardModalChipNumber, setDeleteCardModalChipNumber })}
            loading={loading}
            manual
            onFetchData={onFetchData}
            pages={Math.ceil(data.TotalCount / data.Limit)}
            pageSize={data.Limit}
            showPageSizeOptions={false}
            onPageChange={onPageChange}
            onSortedChange={onSortedChange}
            multiSort={false}
            showPageJump={false}
            {...tableStrings}
          />
        </section>
      </section>
      <ModalSection
        exchangeCardModalChipNumber={exchangeCardModalChipNumber}
        deleteCardModalChipNumber={deleteCardModalChipNumber}
        modalRoot={CardHotelOverviewRoot}
        onExchangeModalClose={onExchangeModalClose}
        onDeleteModalClose={onDeleteModalClose}
        tableRows={data.CompanyCards}
      />
    </>
  )
}
