import React from 'react'
import styled from 'styled-components'

const SwitchContainer = styled.div`
    position: relative;
    width: 100%;
    padding-left: 8px;
`

const Switch = styled.label`
    position: absolute;
    bottom: 0px;
    display: inline-block;
    width: 2em;
    height: 1em;
    margin-left: 8px;
    margin-bottom: 0.2em;
`

const SwitchInput = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
`

const SwitchLabel = styled.span`
`

const SwitchSlider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
    border: 1.5px solid #033a6a;
    border-radius: 1em;

    &::before {
        position: absolute;
        content: "";
        height: 0.76em;
        width: 0.76em;
        left: 0.12em;
        bottom: 0.02em;
        background-color: #033a6a;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
    }

    ${SwitchInput}:checked + & {
        background-color: #033a6a;
    }

    ${SwitchInput}:checked + &::before {
        -webkit-transform: translateX(0.76em);
        -ms-transform: translateX(0.76em);
        transform: translateX(0.76em);
        background-color: #fff;
    }

    ${SwitchInput}:focus + & {
        box-shadow: 0 0 1px #2196F3;
    }
`

export default function ToggleSwitch ({ name, label, toggled, onChange }) {
  return (
    <SwitchContainer>
      <SwitchLabel>{label}</SwitchLabel>
      <Switch>
        <SwitchInput name={name} type='checkbox' checked={toggled} onChange={onChange} />
        <SwitchSlider />
      </Switch>
    </SwitchContainer>
  )
}
