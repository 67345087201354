import React, { useState, useEffect, useContext } from 'react'
import Modal from '../Modal/Modal.js'
import Toaster from '../Toaster/Toaster'
import { StringContext } from '../../StringContext'
import { PageMethodsContext } from '../../PageMethodsContext'

import {
  StyledForm,
  PrimaryButton,
  StyledSectionCardInfo,
  StyledFormSection,
  StyledSubtitle,
  StyledLinkButton,
  StyledMessage
} from './styledComponents'

const urlParams = new URLSearchParams(window.location.search)

const content = {
  chipNumber: urlParams.get('new-card-chip-number') || '',
  expiryDate: urlParams.get('new-card-expiry-date') || '',
  printedCompanyCardNumber: urlParams.get('new-card-printed-number') || '',
  printedCompanyName: urlParams.get('new-card-printed-company-name') || '',
  printedCompanyAddress: urlParams.get('new-card-printed-company-address') || '',
  issuingNation: urlParams.get('new-card-issuing-nation') || ''
}

const NewCardModal = ({ isOpen, ...props }) => {
  const localizedStrings = useContext(StringContext)
  const pm = useContext(PageMethodsContext)
  const [isModalOpen, setIsModalOpen] = useState(isOpen)
  const [isResultVisible, setIsResultVisible] = useState(false)
  const [isToasterVisible, setIsToasterVisible] = useState(false)
  const [isDisabledSearchButton, setDisabledSearchButton] = useState(false)
  const [isDisabledAddCardButton, setDisabledAddCardButton] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState()
  const [customerSearchQuery, setCustomerSearchQuery] = useState(content.printedCompanyName)
  const [statusMessage, setStatusMessage] = useState({ messageType: '', message: '' })
  const [validateCardQuery, setValidateCardQuery] = useState({ chipNumber: content.chipNumber, printedCardNumber: content.printedCompanyCardNumber })
  const modalStrings = localizedStrings.ModalStrings
  const setErrorMessage = (_message) => setStatusMessage({ messageType: 'error', message: _message })
  const setEmptyStatusMessage = () => setStatusMessage({ messageType: '', message: '' })

  const handleCustomerSearchSubmit = async (event) => {
    event.preventDefault()
    setDisabledSearchButton(true)
    setIsToasterVisible(true)
    try {
      const data = await pm.customerSearch(customerSearchQuery)
      console.log('customer search results: ', data)
      showSearchResults(data)
    } catch (error) {
      setErrorMessage(modalStrings.ErrorWhenSearchingForCustomers)
      console.log('customer search error: ', error)
    }
    setDisabledSearchButton(false)
    setIsToasterVisible(false)
  }

  const showSearchResults = (data) => {
    const parsed = JSON.parse(data)
    setSearchResults(parsed)
    if (parsed.length === 0) {
      setErrorMessage(modalStrings.NoCustomersFound)
      setDisabledAddCardButton(true)
    } else {
      setDisabledAddCardButton(false)
      setSelectedCustomer(parseInt(parsed[0].CustomerId, 10))
    }
    setIsResultVisible(true)
  }

  const handleAddCardResult = (data) => {
    const { statusCode, message } = JSON.parse(data)
    let messageType = 'error'
    if (statusCode === 200) {
      messageType = 'success'
      setDisabledAddCardButton(true)
    }
    setStatusMessage({ messageType, message })
  }

  const handleAddCardSubmit = async (event) => {
    event.preventDefault()
    setEmptyStatusMessage()
    setDisabledAddCardButton(true)
    setIsToasterVisible(true)
    const customer = searchResults.find(customer => parseInt(customer.CustomerId, 10) === selectedCustomer)
    const data = {
      customerId: customer.CustomerId,
      companyCardNumber: content.chipNumber,
      expirationDate: content.expiryDate,
      printedCardnumber: content.printedCompanyCardNumber,
      printedCompanyName: content.printedCompanyName,
      issuingNation: content.issuingNation
    }
    console.log('Card info to be saved: ', data)
    var result
    try {
      result = await pm.registerCompanyCardOnCustomer(data)
      handleAddCardResult(result)
      console.warn('Unhandled error when trying to register card', result)
    } catch (error) {
      setErrorMessage(error)
      setDisabledAddCardButton(false)
    }
    setIsToasterVisible(false)
  }

  const handleSearchAgainClick = () => {
    setIsResultVisible(false)
    setDisabledAddCardButton(false)
    setEmptyStatusMessage()
    setCustomerSearchQuery('')
    setValidateCardQuery('')
  }

  useEffect(() => {
    const showValidateCardResult = (data) => {
      const parsed = JSON.parse(data)
      console.log(parsed)
      if (parsed.length !== 0) {
        if (parsed.IsCardExists) {
          setErrorMessage(parsed.Message)
          setDisabledSearchButton(true)
        } else if (parsed.CustomerName) {
          setCustomerSearchQuery(parsed.CustomerName)
        }
      }
    }

    async function validateCardNumber () {
      try {
        const data = await pm.validateCard(validateCardQuery)
        showValidateCardResult(data)
      } catch (error) {
        console.warn('Validate Card number error: ', error)
      }
    }

    setIsModalOpen(isOpen)
    if (isOpen && validateCardQuery) {
      validateCardNumber()
    }
  }, [isOpen, pm, validateCardQuery])

  const openModal =
    <Modal onClose={() => setIsModalOpen(false)} backgroundClose={false} footer={false} id='new-card-modal' size='lg' title={localizedStrings.ModalStrings.RegisterCompanyCard} {...props}>
      <StyledSectionCardInfo className='modal-cardinfo'>
        <p className='modal-icc'><span>{modalStrings.ChipNumber}</span><span>{content.chipNumber}</span></p>
        <p className='modal-printed-nr'><span>{modalStrings.PrintedCardNumber}</span><span>{content.printedCompanyCardNumber}</span></p>
        <p className='modal-expiry'><span>{modalStrings.ExpiryDate}</span><span>{content.expiryDate}</span></p>
        <p className='modal-company-name'><span>{modalStrings.CompanyName}</span><span>{content.printedCompanyName}</span></p>
        <p className='modal-company-address'><span>{modalStrings.CompanyAddress}</span><span>{content.printedCompanyAddress}</span></p>
      </StyledSectionCardInfo>
      <StyledForm onSubmit={handleCustomerSearchSubmit} className={isResultVisible ? 'hidden' : ''}>
        <StyledFormSection>
          <StyledSubtitle>{modalStrings.SearchForCustomer}</StyledSubtitle>
          <div>
            <input
              name='customersearch'
              className='modal-customersearch'
              placeholder='VAT or Customer name'
              value={customerSearchQuery}
              onChange={({ target }) => setCustomerSearchQuery(target.value)}
            />
            <PrimaryButton type='submit' disabled={isDisabledSearchButton}>
              {modalStrings.Search}
            </PrimaryButton>
            <Toaster show={isToasterVisible} size='20px' />
          </div>
        </StyledFormSection>
      </StyledForm>
      <StyledForm onSubmit={handleAddCardSubmit} className={isResultVisible ? '' : 'hidden'}>
        <StyledFormSection>
          <StyledSubtitle>{modalStrings.SearchResults}</StyledSubtitle>
          <StyledLinkButton type='button' onClick={handleSearchAgainClick}>{modalStrings.SearchAgain}</StyledLinkButton>
          <div>
            <select value={selectedCustomer} onChange={({ target }) => { setSelectedCustomer(parseInt(target.value, 10)) }}>
              {searchResults.map(({ CustomerId, VatNumber, CustomerName }) =>
                <option key={CustomerId} value={CustomerId}>
                  {`${CustomerName} ${VatNumber}`}
                </option>
              )}
            </select>
            <PrimaryButton type='submit' disabled={isDisabledAddCardButton}>
              {modalStrings.AddCard}
            </PrimaryButton>
            <Toaster show={isToasterVisible} size='30px' />
          </div>
        </StyledFormSection>
      </StyledForm>
      <StyledMessage messageType={statusMessage.messageType}>{statusMessage.message}</StyledMessage>
    </Modal>
  return isModalOpen ? openModal : ''
}

export default NewCardModal
