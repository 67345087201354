import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import { DefaultThemeProvider, BootstrapRestrictor, BOOTSTRAP_SCOPE_CLASS } from './theme/defaultTheme'

import CardHotelOverview from './sts-components/CardHotelOverview/CardHotelOverview'

import * as serviceWorker from './serviceWorker'

const renderApp = (root) => {
  ReactDOM.render(
    <DefaultThemeProvider>
      <div className={BOOTSTRAP_SCOPE_CLASS}>
        <CardHotelOverview
          CardHotelOverviewRoot={CardHotelOverviewRoot}
          featureToggles={window.featureToggles}
        />
      </div>
      <BootstrapRestrictor />
    </DefaultThemeProvider>,
    root
  )
}

const CardHotelOverviewRoot = document.getElementById('CardHotelOverviewRoot')
if (CardHotelOverviewRoot && window.cardHotelOverviewLocalizedStrings) {
  renderApp(CardHotelOverviewRoot)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
