import React, { useState } from 'react'
import styled from 'styled-components'

const UnstyledUL = styled.ul`
    list-style: none;
    padding-left: 0;
    margin: 0;
`

const TabSwitcher = styled(UnstyledUL)`
    
`

const TabSwitch = styled.li`
    display: inline-block;
    background-color: #808080;
    color: white;
    text-align: center;
    height: 100%;
    width: ${100 / 3 - 1.5}%;
    margin-right: 4px;
    padding-left: 4px;
    padding-right: 4px;

    &:hover {
        cursor: pointer;
        background-color: #505050;
    }

    &.active {
        background-color: black;
    }
`

const TabContentList = styled(UnstyledUL)`
    margin-bottom: 4px;
`

const TabContent = styled.li`
    padding-top: 0;
    padding-bottom: 0;    
    max-height: 800px;
    transition: max-height 0.3s linear;
    overflow: hidden;

    &.closed {
        max-height: 0px;
        transition: max-height 0.15s linear;
    }
`

export default function InfoTabs ({ ...props }) {
  const [selectedTab, setSelectedTab] = useState(null)

  function onTabSelect (event, index) {
    if (selectedTab === index) {
      setSelectedTab(null)
    } else {
      setSelectedTab(index)
    }
  }

  function generateTabContentClassList (index, selectedTabNumber) {
    if (index !== selectedTabNumber) {
      return 'closed'
    }
    return ''
  }

  return (
    <div>
      <TabSwitcher>
        {
          props.tabDeclarations.map((dec, index) => <TabSwitch key={index} className={index === selectedTab ? 'active' : ''} onClick={(e) => { onTabSelect(e, index) }}><span>{dec.tabName}</span></TabSwitch>)
        }
      </TabSwitcher>
      <TabContentList>
        {
          props.tabDeclarations.map((dec, index) => <TabContent key={index} className={'' + generateTabContentClassList(index, selectedTab)}>{dec.tabContent()}</TabContent>)
        }
      </TabContentList>
    </div>
  )
}
