const getCompanyCardsFactory = (pageMethodsObject) => (searchValues) => {
  console.warn('Beware that opening dev tools in Chrome may cause the PageMethod functions to fail for unknown reasons')
  return new Promise((resolve, reject) =>
    pageMethodsObject.GetCompanyCards(searchValues, resolve, reject)
  )
}

const customerSearchFactory = (pageMethodsObject) => (searchString) => new Promise((resolve, reject) =>
  pageMethodsObject.GetCardHotelCustomersBySearchTerm(searchString, resolve, reject)
)

const validateCardFactory = (pageMethodsObject) => ({ chipNumber, printedCardNumber }) => new Promise((resolve, reject) =>
  pageMethodsObject.ValidateCard(chipNumber, printedCardNumber, resolve, reject)
)

const handleRequestCardExchangeFactory = (pageMethodsObject) => ({ LegacyCustomerId, ChipNumber }) => new Promise((resolve, reject) =>
  pageMethodsObject.RequestCardBackForCustomer(LegacyCustomerId, ChipNumber, resolve, reject)
)
  .then((result) => JSON.parse(result))

const handleRequestCardDeleteFactory = (pageMethodsObject) => ({ LegacyCustomerId, ChipNumber }) => new Promise((resolve, reject) =>
  pageMethodsObject.RemoveCompanyCard(LegacyCustomerId, ChipNumber, resolve, reject)
)
  .then((result) => JSON.parse(result))

const registerCompanyCardOnCustomerFactory = (pageMethodsObject) => ({
  customerId,
  companyCardNumber,
  expirationDate = null,
  printedCardnumber = null,
  printedCompanyName = null,
  issuingNation = null
}) => new Promise((resolve, reject) =>
  pageMethodsObject.RegisterCompanyCardOnCustomer(customerId, companyCardNumber, expirationDate, printedCardnumber, printedCompanyName, issuingNation, resolve, reject)
)

const promisifyPageMethods = (pageMethodsObject) => ({
  getCompanyCards: getCompanyCardsFactory(pageMethodsObject),
  customerSearch: customerSearchFactory(pageMethodsObject),
  validateCard: validateCardFactory(pageMethodsObject),
  handleRequestCardExchange: handleRequestCardExchangeFactory(pageMethodsObject),
  handleRequestCardDelete: handleRequestCardDeleteFactory(pageMethodsObject),
  registerCompanyCardOnCustomer: registerCompanyCardOnCustomerFactory(pageMethodsObject)
})

export { promisifyPageMethods }
