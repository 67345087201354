import React, { useState, useEffect, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import Toaster from '../Toaster/Toaster'
import { ScaniaButton, BOOTSTRAP_SCOPE_CLASS } from '../../theme/defaultTheme'
import { StringContext } from '../../StringContext'
import { PageMethodsContext } from '../../PageMethodsContext'

import {
  StyledMessage,
  StyledModal,
  StyledModalHeader,
  StyledModalHeading,
  StyledModalFooter,
  StyledWarning,
  StyledToasterWrapper,
  StyledFlexColumn
} from './styledComponents'

const DeleteCardModal = ({ companyCard = {}, isOpen, onClose }) => {
  const localizedStrings = useContext(StringContext)
  const pm = useContext(PageMethodsContext)
  // @TODO: Translate the following
  const title = 'Are you sure you want to delete this company card?'
  const confirmText = 'Yes, delete company card'

  const [isToasterVisible, setIsToasterVisible] = useState(false)
  const [isOkButtonVisible, setOkButtonVisibility] = useState(false)
  const [statusMessage, setStatusMessage] = useState({ messageType: '', message: '' })
  const [areButtonsEnabled, enableButtons] = useState({ confirm: true, cancel: true })
  const modalStrings = localizedStrings.ModalStrings
  const setErrorMessage = (message) => setStatusMessage({ messageType: 'error', message })
  const setEmptyStatusMessage = () => setStatusMessage({ messageType: '', message: '' })

  const handleConfirmClick = async (event) => {
    event.preventDefault()
    setEmptyStatusMessage()
    enableButtons({ confirm: false, cancel: false })
    setIsToasterVisible(true)
    try {
      const result = await pm.handleRequestCardDelete(companyCard)
      handleOnConfirmResponse(result)
    } catch (error) {
      setErrorMessage(error._message)
      enableButtons({ confirm: false, cancel: true })
    }
    setIsToasterVisible(false)
  }

  const handleOnConfirmResponse = ({ statusCode, message }) => {
    const messageType = statusCode === 200 ? 'success' : 'error'
    setStatusMessage({ messageType, message })
    enableButtons({ confirm: true, cancel: true })
    setOkButtonVisibility(true)
  }

  useEffect(() => {
    setEmptyStatusMessage()
    enableButtons({ confirm: true, cancel: true })
  }, [isOpen])

  const id = 'delete-card-modal'

  const headerComponent = (
    <StyledFlexColumn>
      <StyledWarning><i /></StyledWarning>
      <StyledModalHeading className='modal-title' id={`${id}-label`}>{title}</StyledModalHeading>
    </StyledFlexColumn>
  )

  const footer = !isOkButtonVisible ? (
    <>
      <ScaniaButton type='danger' disabled={!areButtonsEnabled.confirm} onClick={handleConfirmClick}>
        {confirmText}
      </ScaniaButton>
      <ScaniaButton type='secondaryOutline' disabled={!areButtonsEnabled.cancel} onClick={(event) => { onClose(event, statusMessage) }}>
        {localizedStrings.ModalStrings.Cancel}
      </ScaniaButton>
    </>
  ) : (
    <ScaniaButton type='primary' onClick={(event) => { onClose(event, statusMessage) }}>
            OK
    </ScaniaButton>
  )

  return (
    <StyledModal
      show={isOpen}
      onHide={(event) => { onClose(event, statusMessage) }}
      onExited={() => setOkButtonVisibility(false)}
      backdrop='static'
      className={BOOTSTRAP_SCOPE_CLASS}
    >
      <StyledModalHeader>
        {headerComponent}
      </StyledModalHeader>

      <Modal.Body>
        <p>By deleting this company card {companyCard.PrintedCompanyCardNumber || ''}, the related information about the card will be removed from the system.</p>
        <p>This should be done when the card has been returned from the central card hotel.</p>
        <p><b>{modalStrings.CompanyName} </b>: {companyCard.CustomerName}<br />
          <b>{modalStrings.PrintedCardNumber}</b>: {companyCard.PrintedCompanyCardNumber}<br />
          <b>{modalStrings.ChipNumber}</b>: {companyCard.ChipNumber}
        </p><br />
        <p>{modalStrings.CannotBeUndone}</p>
        <StyledToasterWrapper size='30px'>
          <Toaster show={isToasterVisible} size='30px' />
        </StyledToasterWrapper>
        <StyledMessage messageType={statusMessage.messageType}>{statusMessage.message}</StyledMessage>
      </Modal.Body>
      <StyledModalFooter className={isOkButtonVisible ? 'ok-footer' : 'confirm-cancel-footer'}>
        {footer}
      </StyledModalFooter>
    </StyledModal>
  )
}

export default DeleteCardModal
