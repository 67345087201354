// Note, this is not a react component
import React from 'react'
import styled from 'styled-components'

import MeatballMenu from '../MeatballMenu/MeatballMenu'
import TrackCompanyCard from '../Tooltip/TrackCompanyCard'
import { HelpImage, CompanyCardImage, InformationImage } from '../Tooltip/Constants'
import ToolTipHover from '../Tooltip/ToolTipHover'
import ColumnTooltiptext from '../Tooltip/ColumnTooltiptext'
import StatusToolTiptext from '../Tooltip/StatusToolTiptext'

import '../Tooltip/Popover-bootstrap.min.css'
import './../Tooltip/tooltip.css'

const STATUS_EXPIRED = 3

const CellText = styled.span`
  font-size: 0.9em;
  display: block;
  span {
    display: block;
    font-size: 0.8em;
    color:rgb(94,94,94)
  }
  .statusDetails{
    font-size: 0.9em;
  }
`

const StyledExpiredSpan = styled.span`
  &.expired.expired.expired {
    color: #f2855f;
    &::before {
      content: '';
      display: inline-block;
      border: 5px solid #f2855f;
      border-radius: 5px;
      margin-right: 4px;
    }
  }
`

function generateCustomerDetailsLink (importCustomerId, countryCode) {
  var basepath = window.location.href.indexOf('localhost') > 0 ? '/Default.aspx' : '/Admin/Default.aspx'
  return basepath + '?CustomerId=' + importCustomerId + '&CountryCode=' + countryCode
}

function showStatusDetails (statusInfo = null) {
  return statusInfo !== null && statusInfo.replace(/ .*/, '') !== 0 ? statusInfo.split('\n').map((item, i) => <span className='statusDetails' key={i}>{item}</span>) : null
}

export default function getTableColumns ({ localizedStrings, featureToggles, setExchangeCardModalChipNumber, setDeleteCardModalChipNumber }) {
  const cols = localizedStrings.Columns

  const statusIntToStringMap = [
    localizedStrings.StatusOptions.Unavailable,
    localizedStrings.StatusOptions.Available,
    localizedStrings.StatusOptions.Busy,
    localizedStrings.StatusOptions.Expired
  ]

  const statusDetailToStringMap = [
    localizedStrings.StatusDetails.Null,
    localizedStrings.StatusDetails.Servicecancelled,
    localizedStrings.StatusDetails.Exchangerequested

  ]

  const miscellaneous = localizedStrings.Miscellaneous

  return [
    {
      Header: () => (
        <>
          {cols.CustomerName}{' '}
          <ToolTipHover
            title={cols.CustomerName}
            children={<HelpImage src={InformationImage} />}
            component={
              <ColumnTooltiptext
                content={([localizedStrings.CustomerColumnTooltip.CustomerTooltipLine1])}
              />
            }
            placement='right' onMouseEnter={() => { }} delay={200}
          />
        </>
      ),
      accessor: 'CustomerName',
      width: 250,
      Cell: row =>
        <CellText>
          <a className='link' href={generateCustomerDetailsLink(row.original.LegacyCustomerId, row.original.LegacyCountryCode)}>{row.value}</a>
          <span>{row.original.VatNumber}</span>
        </CellText>
    },
    {
      Header: () => (
        <>
          {cols.CompanyCardNumber.Header}{' '}
          <ToolTipHover
            title={cols.CompanyCardNumber.Header}
            children={<HelpImage src={InformationImage} />}
            component={
              <ColumnTooltiptext
                content={([localizedStrings.CompanyCardTooltip.CompanyCardTooltipLine1, localizedStrings.CompanyCardTooltip.CompanyCardTooltipLine2, localizedStrings.CompanyCardTooltip.CompanyCardTooltipLine3])}
              />
            }
            placement='right' onMouseEnter={() => { }} delay={200}
          />
        </>
      ),
      accessor: 'CompanyCardNumber',
      Cell: row =>
        <CellText>
          {row.original.PrintedCompanyCardNumber === 'Unknown' ? miscellaneous.Unknown : row.original.PrintedCompanyCardNumber}
          <span>{row.original.ChipNumber}</span>
        </CellText>
    },
    {
      id: 'ExchangeCard',
      width: 35,
      accessor: 'ExchangeCardFlag',
      Cell: row => {
        return row.value ? row.original.TrackingDetails.TrackingNumber !== '' ? (
          <ToolTipHover
            title={localizedStrings.TrackingToolTip.Header}
            children={<HelpImage src={CompanyCardImage} className='companycard-img' />}
            component={
              <TrackCompanyCard
                TrackingNumber={row.original.TrackingDetails.TrackingNumber} ShippingDate={row.original.TrackingDetails.ShippingDate}
                TransportCompany={row.original.TrackingDetails.ShippingCompany} ColHeader={localizedStrings.TrackingToolTip}
              />
            }
            placement='right' onMouseEnter={() => { }} delay={200}
          />
        ) : (<span>{}</span>) : (<span>{}</span>)
      }
    },
    {
      id: 'StatusText',
      width: 180,
      Header: () => (
        <>
          {cols.Status}{' '}
          <ToolTipHover
            title={cols.Status}
            children={<HelpImage src={InformationImage} />}
            component={
              <StatusToolTiptext
                StatusToolTip={([localizedStrings.StatusToolTip.StatusToolTipLine1, localizedStrings.StatusToolTip.StatusToolTipLine2, localizedStrings.StatusToolTip.StatusToolTipLine3, localizedStrings.StatusToolTip.StatusToolTipLine4, localizedStrings.StatusToolTip.StatusToolTipLine5, localizedStrings.StatusToolTip.StatusToolTipLine6, localizedStrings.StatusToolTip.StatusToolTipLine7, localizedStrings.StatusToolTip.StatusToolTipLine8])}
              />
            }
            placement='right' onMouseEnter={() => { }} delay={200}
          />
        </>
      ),
      accessor: 'StatusInt',
      sortMethod: (a, b) => {
        return statusIntToStringMap[a] < statusIntToStringMap[b] ? 1 : -1
      },
      Cell: row =>
        <CellText>
          {statusIntToStringMap[row.value]}
          {showStatusDetails(statusDetailToStringMap[row.original.StatusDetails.replace(/ .*/, '')])}
          {showStatusDetails(row.original.StatusDetails.split(/\s+/)[1])}
        </CellText>
    },
    {
      Header: () => (
        <>
          {cols.ExpirationDate.Header}{' '}
          <ToolTipHover
            title={cols.ExpirationDate.Header}
            children={<HelpImage src={InformationImage} />}
            component={
              <ColumnTooltiptext
                content={([localizedStrings.ExpiryDateTooltip.ExpiryDateTooltipLine1])}
              />
            }
            placement='right' onMouseEnter={() => { }} delay={200}
          />

          {/* <Tooltip title={cols.ExpirationDate.Header}  /> */}
        </>
      ),
      accessor: 'ExpirationDate',
      Cell: row => {
        const rowStatus =
                      row.original !== null &&
                          typeof row.original.StatusInt !== 'undefined'
                        ? row.original.StatusInt
                        : null
        const isExpired = rowStatus === STATUS_EXPIRED

        return row.value !== null && row.value.includes('|') ? (
          <CellText className={isExpired ? 'expired' : ''}>
            {row.value.split('|')[1]}
            <StyledExpiredSpan className={isExpired ? 'expired' : ''}>
              {isExpired ? localizedStrings.StatusOptions.Expired : ''}
            </StyledExpiredSpan>
          </CellText>
        ) : (
          <CellText>
            {localizedStrings.ExpirationSearchOptions.Unknown}
          </CellText>
        )
      }
    },
    meatballMenuColumn({
      onExchangeCardRequest: (companyCard) => setExchangeCardModalChipNumber(companyCard.ChipNumber),
      onDeleteCard: (companyCard) => setDeleteCardModalChipNumber(companyCard.ChipNumber),
      featureToggles,
      localizedStrings
    })
  ]
}

/**
   * Creates a react table column with a Meatball menu in each row
   *
   * @param {function onExchangeRequest(chipNumber)} The function to be called when the Request Excange Card menu item is selected.
   * @param {function onDelete(chipNumber)} The function to be called when the Delete Card menu item is selected.
   * @return {Object} An object compatible with the React Table column api.
   */
const meatballMenuColumn = ({ onExchangeCardRequest, onDeleteCard, featureToggles, localizedStrings }) => {
  const menuItems = []

  if (featureToggles.toggleExchangeCard) {
    menuItems.push({
      value: localizedStrings.Columns.RowActionMenyOptions.ExchangeAction,
      onSelect: (event, companyCard) => { onExchangeCardRequest(companyCard) },
      isButtonDisabled: (item) => item.ExchangeRequested || (item.StatusInt === 0 || item.StatusInt === 3),
      disabledInfo: (item) => item.ExchangeRequested ? localizedStrings.Columns.RowActionMenyOptions.ExchangeActionDisabledTooltipAlreadyRequestedBack : localizedStrings.Columns.RowActionMenyOptions.ExchangeActionDisabledTooltipCardUnavailable
    })
  }

  if (featureToggles.toggleDeleteCard) {
    menuItems.push({
      value: localizedStrings.Columns.RowActionMenyOptions.DeleteAction,
      onSelect: (event, companyCard) => { onDeleteCard(companyCard) },
      isButtonDisabled: (item) => !(item.ExchangeRequested && item.ExchangeCardFlag),
      disabledInfo: (item) => localizedStrings.Columns.RowActionMenyOptions.DeleteActionDisabledTooltip
    })
  }

  return {
    width: 50,
    Cell: (row) => menuItems.length > 0 ? (<MeatballMenu callbackArgument={row.original} menuItems={menuItems} />) : ''
  }
}
