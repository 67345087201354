import React from 'react'
import styled from 'styled-components'

import NewCardModal from '../NewCardModal/NewCardModal'
import ExchangeCardModal from '../ExchangeCardModal/ExchangeCardModal'
import DeleteCardModal from '../DeleteCardModal/DeleteCardModal'
import AddCardManuallyModal from '../BackOffice/AddCardManuallyModal'

const urlParams = new URLSearchParams(window.location.search)

const StyledSection = styled.section`
  padding: 20px 0;
`

export default ({
  modalRoot,
  onExchangeModalClose,
  onDeleteModalClose,
  exchangeCardModalChipNumber,
  deleteCardModalChipNumber,
  tableRows = []
}) => {
  return (
    <StyledSection>
      <NewCardModal
        isOpen={!!urlParams.get('new-card-chip-number')}
        modalRoot={modalRoot}
      />
      <ExchangeCardModal
        isOpen={!!exchangeCardModalChipNumber}
        companyCard={tableRows.find((c) => c.ChipNumber === exchangeCardModalChipNumber) || {}}
        onClose={onExchangeModalClose}
      />
      <DeleteCardModal
        isOpen={!!deleteCardModalChipNumber}
        companyCard={tableRows.find((c) => c.ChipNumber === deleteCardModalChipNumber) || {}}
        onClose={onDeleteModalClose}
      />
      {urlParams.get('backoffice') && <AddCardManuallyModal show='true' />}
    </StyledSection>
  )
}
