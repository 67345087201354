import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { BOOTSTRAP_SCOPE_CLASS } from '../../theme/defaultTheme'

import {
  StyledContextMenu,
  StyledMeatballButton
} from './styledComponents'

/**
 * Component for showing a "Meatball menu" a.k.a. "dot dot dot" or "ellipsis" menu.
 * @component
 * @param {Object} props
 * @param {Object[]} props.menuItems - the menu items to be rendered
 * @param {string} props.menuItems[].value - the text of the menu item
 * @param {function} props.menuItems[].onSelect - the function to be called when the item is selected.
 * @param {*} props.callbackArgument - an argument that will be passed on to the onSelect function of the menuItems
 */
const MeatballMenu = ({ callbackArgument, menuItems }) => {
  const menu = (
    <StyledContextMenu className={BOOTSTRAP_SCOPE_CLASS} role='menu'>
      {menuItems.map((item, index) => (
        <li
          key={index}
          role='presentation'
        >
          <button
            type='button'
            role='menuitem'
            tab-index='-1'
            disabled={item.isButtonDisabled(callbackArgument)}
            title={item.isButtonDisabled(callbackArgument) ? item.disabledInfo(callbackArgument) : ''}
            onKeyPress={(event) => { onMenuItemSelected(event, item.onSelect) }}
            onClick={(event) => { onMenuItemSelected(event, item.onSelect) }}
          >
            {item.value}
          </button>
        </li>
      ))}
    </StyledContextMenu>
  )

  const onMenuItemSelected = (event, func) => {
    event.preventDefault()
    func(event, callbackArgument)
  }

  return (
    <OverlayTrigger trigger={['click', 'focus']} placement='bottom' overlay={menu}>
      {/* make sure the menu is closed on blur, but skip the focus trigger (which conflicts with the click trigger) */}
      {({ onClick, ref, onBlur }) => {
        return (
          <StyledMeatballButton
            onKeyPress={(event) => { event.preventDefault() }}
            onClick={(event) => { event.preventDefault(); onClick(event) }}
            onBlur={onBlur}
            ref={ref}
            aria-haspopup='true'
          >
            &hellip;
          </StyledMeatballButton>
        )
      }}
    </OverlayTrigger>
  )
}

export default MeatballMenu
