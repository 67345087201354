import React, { useEffect, useRef, useContext } from 'react'
import styled from 'styled-components'
import { StringContext } from '../StringContext'

import Input from './Input'
import Select from './Select'
import ToggleSwitch from './ToggleSwitch'

const ControlsWrapper = styled.div`
  padding: 12px 16px;
`

const SearchBoxRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  margin-top: 4px;
`

const SearchBoxRowReversed = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4px;
  margin-top: 4px;
`

const ClearSearchFiltersBox = styled.div`
  width: 33%;
  text-align: right;
`

const TrackTraceFilterBox = styled.div`
  width: 65%;
  text-align: right;
  padding-right: 3.2em;
`

const ClearSearchFiltersButton = styled.button`
  color: blue;
  border: none;
  background: transparent;
`

const makeOptions = (values, strings) =>
  values.map(value => {
    const text = strings && strings[value] ? strings[value] : ''
    return Object.assign({}, { value, text })
  })

export default function SearchBox ({ changeHandler, ...props }) {
  const localizedStrings = useContext(StringContext)
  const forceSearch = useRef(false)
  const firstUpdate = useRef(true)
  const previousPropValues = useRef(null)
  const minSearchFieldLength = 3
  const lastSearchTermChange = useRef(null)
  const currentSearchTimeoutId = useRef(null)
  const waitDuration = 800

  const handleChange = event => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    const control = event.target.name

    changeHandler({ control, value })
    lastSearchTermChange.current = Date.now()
  }

  const deriveLabelsFromProps = () => (
    localizedStrings.Columns || {
      CustomerName: ' ',
      VatNumber: ' ',
      CompanyCardNumber: ' ',
      StatusText: ' ',
      ExpirationDate: ' '
    }
  )

  const createSearchParametersObject = ({ customerName, companyCardNumber, expiredStatus, vatNumber, status, trackTraceNumber, withTrackAndTrace }) => ({
    customerName,
    companyCardNumber,
    expiredStatus,
    vatNumber,
    status,
    trackTraceNumber,
    withTrackAndTrace,
    triggersDelayedSearch: ['customerName', 'companyCardNumber', 'vatNumber', 'trackTraceNumber'],
    triggersSearch: ['expiredStatus', 'status', 'withTrackAndTrace']
  })

  const executeSearch = () => {
    lastSearchTermChange.current = null
    props.handleSubmit({ preventDefault: () => { } })
  }

  useEffect(() => {
    const currentSearchParameters = createSearchParametersObject(props)
    if (firstUpdate.current === true) {
      previousPropValues.current = currentSearchParameters
      firstUpdate.current = false
      return
    }

    let shouldDoSearch = false

    currentSearchParameters.triggersSearch.forEach((key) => {
      if (currentSearchParameters[key] !== previousPropValues.current[key]) {
        shouldDoSearch = true
      }
    })

    if (shouldDoSearch || forceSearch.current) {
      if (currentSearchTimeoutId.current !== null) {
        clearTimeout(currentSearchTimeoutId.current)
      }

      executeSearch()
      forceSearch.current = false
    }

    currentSearchParameters.triggersDelayedSearch.forEach((key) => {
      if (currentSearchParameters[key] !== previousPropValues.current[key]) {
        if (currentSearchParameters[key].length >= minSearchFieldLength || currentSearchParameters[key] === '') {
          shouldDoSearch = true
        }
      }
    })

    if (shouldDoSearch) {
      if (currentSearchTimeoutId.current !== null) {
        clearTimeout(currentSearchTimeoutId.current)
      }

      currentSearchTimeoutId.current = setTimeout(() => { executeSearch() }, waitDuration - (Date.now() - lastSearchTermChange.current))
    }

    previousPropValues.current = currentSearchParameters
  }, [props.customerName, props.companyCardNumber, props.vatNumber, props.expiredStatus, props.status, props.trackTraceNumber, props.withTrackAndTrace])

  const handleClearSearchFiltersClicked = (event) => {
    forceSearch.current = true
    props.handleClearSearchFilters(event)
  }

  const deriveStatusesFromProps = () => {
    const values = ['All', 'Unavailable', 'Available', 'Busy']
    const strings = localizedStrings.StatusOptions
    const options = makeOptions(values, strings)
    return options
  }

  const deriveExpirationOptionsFromProps = () => {
    const values = ['All', 'Expired', 'Unknown']
    const strings = localizedStrings.ExpirationSearchOptions
    const options = makeOptions(values, strings)
    return options
  }

  const labels = deriveLabelsFromProps()
  const statuses = deriveStatusesFromProps()
  const expirationDateOptions = deriveExpirationOptionsFromProps()

  return (
    <ControlsWrapper>
      <SearchBoxRow>
        <Input
          name='customerName'
          label={labels.CustomerName}
          value={props.customerName}
          onChange={handleChange}
        />
        <Input
          name='companyCardNumber'
          label={labels.CompanyCardNumber.Header}
          value={props.companyCardNumber}
          onChange={handleChange}
        />
        <Select
          name='expiredStatus'
          label={labels.ExpirationDate.Header}
          options={expirationDateOptions}
          value={props.expiredStatus}
          onChange={handleChange}
        />
      </SearchBoxRow>
      <SearchBoxRow>
        <Input
          name='vatNumber'
          label={labels.VatNumber}
          value={props.vatNumber}
          onChange={handleChange}
        />
        <Input
          name='trackTraceNumber'
          label='Track & Trace'
          value={props.trackTraceNumber}
          onChange={handleChange}
        />
        <Select
          name='status'
          label={labels.Status}
          options={statuses}
          value={props.status}
          onChange={handleChange}
        />
      </SearchBoxRow>
      <SearchBoxRowReversed>
        <TrackTraceFilterBox>
          <ToggleSwitch name='withTrackAndTrace' label={localizedStrings.ButtonStrings.ShowOnlyCardsWithTrackTraceNumber} toggled={props.withTrackAndTrace} onChange={handleChange} />
        </TrackTraceFilterBox>
        <ClearSearchFiltersBox>
          <ClearSearchFiltersButton type='button' onClick={handleClearSearchFiltersClicked}>{localizedStrings.ButtonStrings.ClearSearchFilters}</ClearSearchFiltersButton>
        </ClearSearchFiltersBox>
      </SearchBoxRowReversed>
    </ControlsWrapper>
  )
}

export const getInitialSearchValues = ({ vat, rowsPerPage }) => ({
  customerName: '',
  vatNumber: vat || '',
  companyCardNumber: '',
  expiredStatus: '',
  status: '',
  trackTraceNumber: '',
  withTrackAndTrace: false,
  offset: 0,
  limit: rowsPerPage
})
