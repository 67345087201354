import { createContext } from 'react'
import { promisifyPageMethods } from './promisifyPageMethods'

const pm = window.PageMethods || {}

const promisifiedPageMethods = promisifyPageMethods(pm)

const PageMethodsContext = createContext(promisifiedPageMethods)

export { PageMethodsContext, promisifyPageMethods }
