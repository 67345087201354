import React from 'react'
import './../Tooltip/tooltip.css'

export default function ColumnTooltiptext ({ content }) {
  return (
    <>
      <div className='tooltip-Width'>
        {Object.keys(content).map((key) => (
          <span key={key}>{content[`${key}`]}</span>
        ))}
      </div>
    </>
  )
}
