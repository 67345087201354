import React from 'react'
import styled from 'styled-components'
import SearchControlWrapper from './styled-components/SearchControlWrapper'

const Label = styled.label`
  min-width: 100px;
  min-height: 1.2em;
  display: inline-block;
  font-size: 1em;
`

const InputField = styled.input`
  width: 100%;
  height: 1.6em;
`

const ClearFieldButton = styled.span`
  display: block;
  cursor: pointer;
  margin-top: -30px;
  margin-left: 92%;
  font-size: 20px;
  color: #777;
`

export default function Input ({ label, ...props }) {
  const clearField = () => {
    props.onChange(
      {
        target: {
          name: props.name,
          type: 'input',
          value: ''
        }
      }
    )
  }

  return (
    <SearchControlWrapper>
      <Label htmlFor={props.name}>{label}</Label>
      <div>
        <InputField {...props} type='text' />
        {props.value.length > 0 ? <ClearFieldButton onClick={clearField}>{'\u00D7'}</ClearFieldButton> : ''}
      </div>
    </SearchControlWrapper>
  )
}
