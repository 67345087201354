import React, { useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import { BOOTSTRAP_SCOPE_CLASS } from '../../../theme/defaultTheme'
import styled from 'styled-components'

const StyledButtons = styled.div`
  border-top: none !important;
  padding: 0 53px 50px 53px !important;
  width: 100%;
  display: flex;
  justify-content: space-around !important;
  button {
    flex-basis: 48%;
    min-height: 42px;
  }
`

const AddCardManuallyModal = ({ show }) => {
  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setValidated(true)
    const form = event.currentTarget

    if (!form.checkValidity()) {
      return
    }

    const chipNumber = form.querySelector('#formChipNumber').value.replace(/-/g, '')
    const companyName = form.querySelector('#formCompanyName').value
    const companyAddress = form.querySelector('#formCompanyAddress').value
    const printedCardNumber = form.querySelector('#formPrintedNumber').value
    const expiryDate = form.querySelector('#formExpiryDate').value
    const issuingNation = form.querySelector('#formIssuingNation').value
    const newLocation = [
        `new-card-chip-number=${chipNumber}`,
        `new-card-printed-number=${printedCardNumber}`,
        `new-card-printed-company-name=${companyName}`,
        `new-card-printed-company-address=${companyAddress}`,
        `new-card-issuing-nation=${issuingNation}`,
        `new-card-expiry-date=${expiryDate}`
    ].join('&')

    const newBase = form.baseURI.split('?backoffice')[0]
    window.location = `${newBase}?${newLocation}`
  }

  const [isAddCardManuallyDialogOpen, setAddCardManuallyDialogOpen] = useState(!!show)
  const [validated, setValidated] = useState(false)
  return (
    <Modal
      show={isAddCardManuallyDialogOpen}
      onHide={() => { setAddCardManuallyDialogOpen(false) }}
      className={BOOTSTRAP_SCOPE_CLASS}
    >
      <Modal.Header closeButton>
        <h1>Add card manually</h1>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group required controlId='formChipNumber'>
            <Form.Label>Chip Number (with or without hyphens between bytes)</Form.Label>
            <Form.Control required type='text' placeholder='Enter chip number' />
            <Form.Text className='text-muted'>
                  The chip number that is stored on the card, but not printed on the surface.
            </Form.Text>
          </Form.Group>
          <Form.Group controlId='formIssuingNation'>
            <Form.Label>Issuing nation</Form.Label>
            <Form.Control type='text' placeholder='Enter issuing nation' />
            <Form.Text className='text-muted'>
                  Country code of the country issuing the card.
            </Form.Text>
          </Form.Group>
          <Form.Group controlId='formPrintedNumber'>
            <Form.Label>Printed Card Number</Form.Label>
            <Form.Control type='text' placeholder='Enter printed card number' />
            <Form.Text className='text-muted'>
                  The card number that is printed on the surface of the card.
            </Form.Text>
          </Form.Group>
          <Form.Group controlId='formCompanyName'>
            <Form.Label>Company name</Form.Label>
            <Form.Control type='text' placeholder='Enter company name' />
          </Form.Group>
          <Form.Group controlId='formCompanyAddress'>
            <Form.Label>Company address</Form.Label>
            <Form.Control type='text' placeholder='Enter company address' />
          </Form.Group>
          <Form.Group controlId='formExpiryDate'>
            <Form.Label>Date of expiry</Form.Label>
            <Form.Control type='text' placeholder='Enter date of expiry' />
          </Form.Group>
          <StyledButtons>
            <Button variant='secondary' onClick={() => { setAddCardManuallyDialogOpen(false) }}>
                    Close
            </Button>
            <Button type='submit' variant='primary'>
                    Open Standard Add Card Modal
            </Button>
          </StyledButtons>
        </Form>
      </Modal.Body>
    </Modal>)
}

export default AddCardManuallyModal
