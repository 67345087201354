import Modal from 'react-bootstrap/Modal'
import styled from 'styled-components'

const StyledMessage = styled.p`
    font-size: 1em;
    font-weight: 600;
    color: ${({ messageType }) => messageType === 'error' ? 'red' : 'green'};
`

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 666px;
    .modal-body {
      padding-right: 60px;
      padding-left: 60px;
    }
  }
`

const StyledModalHeader = styled(Modal.Header)`
  border-bottom: none !important;
  margin: auto;
  margin-right: 60px;
  margin-left: 60px;
`
const StyledModalHeading = styled.h5`
  margin-top: 19px;
  text-align: center;
  font-size: 21px;
`

const StyledModalFooter = styled(Modal.Footer)`
  border-top: none !important;
  padding: 0 53px 50px 53px !important;
  width: 100%;
  display: flex;
  button {
    flex-basis: 48%;
    min-height: 42px;
  }
  &.confirm-cancel-footer {
    justify-content: space-between !important;
  }  
  &.ok-footer {
    justify-content: space-around !important;
  }
`

const StyledWarning = styled.div`
  width: 49px;
  height: 49px;
  background-color: #cc1040;
  background-size: cover;
  border-radius: 25px;
  i {
    display: block;
    width: 25px;
    height: 25px;
    margin: 10px auto;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIG92ZXJmbG93PSJ2aXNpYmxlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PGc+PHBhdGggeG1sbnM6ZGVmYXVsdD0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJleGNsYW1hdGlvbi10cmlhbmdsZSIgZD0iTTEzLjI0LDE3LjljLTAuMDYsMC4wNi0wLjE0LDAuMS0wLjIzLDAuMWgtMmMtMC4wOSwwLTAuMTctMC4wNC0wLjIzLTAuMSAgYy0wLjA2LTAuMDYtMC4xLTAuMTUtMC4xLTAuMjR2LTJjMC0wLjA5LDAuMDQtMC4xOCwwLjEtMC4yNGMwLjA2LTAuMDYsMC4xNC0wLjEsMC4yMy0wLjFoMmMwLjA5LDAsMC4xNywwLjA0LDAuMjMsMC4xICBjMC4wNiwwLjA2LDAuMSwwLjE1LDAuMSwwLjI0djJDMTMuMzQsMTcuNzUsMTMuMywxNy44MywxMy4yNCwxNy45eiBNMTMuMzIsMTMuNzZjLTAuMDEsMC4wNy0wLjA1LDAuMTMtMC4xMSwwLjE3ICBjLTAuMDYsMC4wNC0wLjEzLDAuMDYtMC4yLDAuMDdoLTJjLTAuMDksMC0wLjE4LTAuMDItMC4yNS0wLjA3Yy0wLjA2LTAuMDQtMC4xLTAuMS0wLjEtMC4xN0wxMC41MSw5Yy0wLjAxLTAuMDksMC4wMy0wLjE3LDAuMS0wLjIyICBjMC4wNy0wLjA2LDAuMTYtMC4xLDAuMjUtMC4xMWgyLjI5YzAuMDksMC4wMSwwLjE4LDAuMDUsMC4yNSwwLjExYzAuMDcsMC4wNCwwLjEsMC4xMiwwLjEsMC4yTDEzLjMyLDEzLjc2TDEzLjMyLDEzLjc2TDEzLjMyLDEzLjc2ICB6IE0xMi42OSwzLjUzYy0wLjQyLTAuMjUtMC45My0wLjI1LTEuMzUsMGMtMC4yMSwwLjEyLTAuMzgsMC4zLTAuNDksMC41MWwtOCwxNC42NmMtMC4yNSwwLjQtMC4yNSwwLjkxLDAsMS4zMSAgYzAuMjQsMC40MSwwLjY4LDAuNjYsMS4xNiwwLjY1aDE2YzAuNDcsMCwwLjktMC4yNSwxLjE0LTAuNjZjMC4yNS0wLjQsMC4yNS0wLjkxLDAtMS4zMUwxMy4xOCw0QzEzLjA2LDMuOCwxMi44OSwzLjY0LDEyLjY5LDMuNTN6IiBzdHlsZT0iZmlsbDogcmdiKDI1NSwgMjU1LCAyNTUpOyIgdmVjdG9yLWVmZmVjdD0ibm9uLXNjYWxpbmctc3Ryb2tlIi8+PC9nPjwvc3ZnPg==')
  }
`

const StyledToasterWrapper = styled.div`
  width: ${({ size }) => size};
  margin: auto;
`

const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center !important;
  border-bottom: none !important;
  padding-top: 40px;
`

export {
  StyledMessage,
  StyledModal,
  StyledModalHeader,
  StyledModalHeading,
  StyledModalFooter,
  StyledWarning,
  StyledToasterWrapper,
  StyledFlexColumn
}
