
import React from 'react'
import { ThemeProvider } from 'styled-components'
import { ToastLoaderContainer, ToastLoaderSpinner } from './styledComponents'

const theme = {
  primaryColor: '#044883'
}

export default ({ show, size, message, ...props }) => {
  const toast = (
    <ThemeProvider theme={theme}>
      <ToastLoaderContainer size={size}>
        <div>
          <ToastLoaderSpinner size={size} {...props} /><p>{message}</p>
        </div>
      </ToastLoaderContainer>
    </ThemeProvider>
  )

  return show ? toast : ''
}
