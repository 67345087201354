import styled from 'styled-components'

const StyledMeatballButton = styled.button`
    cursor: pointer;
    font-size: 14pt;
    font-weight: bold;
    font-family: "Scania Sans Condensed";
    border: none;
    background: none;
    line-height: 1pt;
    color: #4a8ba4;
    opacity: 0.6;
    &:hover{
        opacity: 1;
    }
    &:focus {
        outline: none;
        text-decoration: underline;
    }
`

const StyledContextMenu = styled.ul`
    margin-top: 20px;
    margin-left: -75px;
    background: white;
    list-style: none;
    padding-left: 0;
    color: #242424;
    border: 1px solid #f0f0f0;
    & li {
      padding: 0;
      width: 180px;
      height: 50px;
      border: 1px solid #f0f0f0;
      background-color: #dce8ed;
      &:nth-child(even) {
        background-color: #fff;
        border-top: none;
      }
      &:hover {
          filter: brightness(98%);
          button {
            font-weight: bold;
            font-family: inherit;
          }
        }
      button {
          cursor: pointer;
          padding: 12px 24px 15px 20px;
          width: 100%;
          height: 100%;
          border: 0;
          margin: 0;
          background: transparent;
          font-size: inherit;
          font-family: inherit;
          text-align: left;
      }
    }
`

export {
  StyledMeatballButton,
  StyledContextMenu
}
