import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
    font-size: 30px;;
    font-stretch
    font-weight: normal;
    padding: 5px;
    margin: -1rem -1rem -1rem auto;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    line-height: 1;
    color: #000;
    box-sizing: border-box;
    font: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    &:hover {
        opacity: .9;
    }
`

const CloseButton = (props) => <StyledButton className='close' aria-label='Close' {...props}><span aria-hidden='true'>x</span></StyledButton>

export default CloseButton
