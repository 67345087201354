import React from 'react'
import styled from 'styled-components'
import SearchControlWrapper from './styled-components/SearchControlWrapper'

const Label = styled.label`
  min-width: 100px;
  min-height: 1.2em;
  display: inline-block;
  font-size: 1em;
`

const DropDown = styled.select`
  width: 100%;
  height: 2.5em;
  font-size: 11px;
`

const ClearFieldButton = styled.span`
  display: block;
  cursor: pointer;
  margin-top: -31px;
  margin-left: 86%;
  font-size: 20px;
  color: #777;
`

export default function Select ({ label, options, ...props }) {
  const clearField = () => {
    props.onChange(
      {
        target: {
          name: props.name,
          type: 'input',
          value: options[0].value
        }
      }
    )
  }

  return (
    <SearchControlWrapper>
      <Label htmlFor={props.name}>{label}</Label>
      <span>
        <DropDown {...props}>
          {options.map((element, index) => (
            <option key={index} value={element.value}>
              {element.text}
            </option>
          ))}
        </DropDown>
        {props.value !== null && props.value !== '' && props.value !== options[0].value ? <ClearFieldButton onClick={clearField}>{'\u00D7'}</ClearFieldButton> : ''}
      </span>
    </SearchControlWrapper>
  )
}
