import React from 'react'
import './../Tooltip/tooltip.css'
import { HelpImage, TrackTraceImage } from '../Tooltip/Constants'

export default function TrackCompanyCard ({ ColHeader, TrackingNumber, ShippingDate, TransportCompany }) {
  return (
    <>
      <div className='tooltip-tracktrace-header'>
        <div className='tracking-col'>
          <p>
            <span>{ColHeader.TrackingToolTipLine1}</span>
          </p>
        </div>
        <div className='tracking-col'>
          <p>{ColHeader.TrackingToolTipLine2}</p>
        </div>
        <div className='tracking-col'>
          <p>{ColHeader.TrackingToolTipLine3}</p>
        </div>
      </div>
      <div className='tooltip-tracktrace-margin'>
        <hr />
      </div>
      <div className='tooltip-tracktrace-content'>
        <div className='tracking-col'>
          <p>
            {TrackingNumber}
          </p>
        </div>
        <div className='tracking-col'>
          <p>{ShippingDate}</p>
        </div>
        <div className='tracking-col'>
          <p>{TransportCompany}</p>
        </div>
      </div>
      <div className='tooltip-tracktrace-margin'>
        <p>
          <br />
        </p>
      </div>
      <div className='tooltip-tracktrace-copy'>
        <HelpImage src={TrackTraceImage} alt=' ' className='tracktrace-iconsize' onClick={() => navigator.clipboard.writeText(TrackingNumber)} />

        <p style={{ cursor: 'pointer' }} onClick={() => navigator.clipboard.writeText(TrackingNumber)}>{ColHeader.TrackingToolTipLine4}</p>
      </div>
    </>
  )
}
