// Adapted from https://gitlab.scania.com/VehiclePerformance/MonitoringReport/monitoring-report-web/-/blob/master/src/util/toast.scss
import styled, { keyframes } from 'styled-components'

const spin = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`

const ToastLoaderSpinner = styled.div`
    border: ${({ stroke }) => stroke} solid ${({ background }) => background}};
    border-top: ${({ stroke }) => stroke} solid ${({ theme }) => theme.primaryColor};
    border-right: ${({ stroke }) => stroke} solid ${({ theme }) => theme.primaryColor};
    border-radius: 50%;
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    animation: ${spin} 1s linear infinite;
    display: inline-block;
    margin-right: 8px;
`

ToastLoaderSpinner.defaultProps = {
  theme: {
    primaryColor: 'black'
  },
  stroke: '3px',
  background: 'transparent',
  size: '20px'
}

const ToastLoaderContainer = styled.span` 
    height: ${({ size }) => size};
    padding: 8px;
    display: inline-block;
    &>div {
        align-items: center;
        display: flex;
    }

`

ToastLoaderContainer.defaultProps = {
  size: '20px'
}

export { ToastLoaderContainer, ToastLoaderSpinner }
