import styled from 'styled-components'

const StyledForm = styled.form`
&.hidden {
    display: none;
}`

const PrimaryButton = styled.button`
    height: 2.3em;
    background: rgb(3, 58, 106);
    border: rgb(3, 58, 106);
    border-radius: 3px;
    padding: 9px 18px 8px;
    color: rgb(250, 250, 250);
    font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    &:hover:not(:disabled), &:focus:not(:disabled)         {
        border-color: rgb(0, 98, 204);
        box-shadow: inset 0 0 0 99999px rgba(255, 255, 255, 0.2);
        color: white;
    }
    &:disabled {
        opacity: .65;
        color: white;
        cursor: auto;
    }
`

const StyledSectionCardInfo = styled.section`
    background: #eee;
    width: 100%;
    box-sizing: border-box;
    padding: 1px 5px 1px 15px;
    margin-bottom: 2em;
    p {
        display: flex;
        font-family: Arial, sans-serif;
    }

    p span:first-child {
        flex: 0 0 175px;
        color: #333;
        text-align: right;
        padding-right: 15px;
        font-weight: bold;
    }
`

const StyledFormSection = styled.section`
    margin-top: 1em;
    max-width: 100%;
    box-sizing: border-box;
    div {
        font-size: 1em;
        box-sizing: content-box;
    }
    button {
        font-size: inherit;
        height: 2.3em;
    }
    select, input {
        padding: 0 0 0 5px;
        font-size: inherit;
        margin: 1em 15px 0 0;
        height: 2.3em;
        width: 60%;
    }
`

const StyledSubtitle = styled.h4`
    font-family: Arial, sans-serif;
    color: #333;
    display: inline;
    margin-right: 2em;
`

const StyledLinkButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
    color: #33e;
  }
  &:hover, &:focus {
  text-decoration: none;
  }
`

const StyledMessage = styled.p`
    font-size: 0.75em;
    color: ${({ messageType }) => messageType === 'error' ? 'red' : 'green'};
`

const StyledMessageWarning = styled.p`
    font-size: 0.70em;
    color:#ffcc00;
`

export { StyledForm, PrimaryButton, StyledSectionCardInfo, StyledFormSection, StyledSubtitle, StyledLinkButton, StyledMessage, StyledMessageWarning }
