import React, { useState, useEffect, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import Toaster from '../Toaster/Toaster'
import { ScaniaButton, BOOTSTRAP_SCOPE_CLASS } from '../../theme/defaultTheme'
import { StringContext } from '../../StringContext'
import { PageMethodsContext } from '../../PageMethodsContext'

import {
  StyledMessage,
  StyledModal,
  StyledModalHeader,
  StyledModalHeading,
  StyledModalFooter,
  StyledWarning,
  StyledToasterWrapper,
  StyledFlexColumn
} from './styledComponents'

const ExchangeCardModal = ({ companyCard = {}, isOpen, onClose }) => {
  const localizedStrings = useContext(StringContext)
  const pm = useContext(PageMethodsContext)
  // @TODO: The property TitleNewCard on the following line seems to be a misnomer that should be fixed:
  const title = localizedStrings.ModalStrings.TitleNewCard
  const [isToasterVisible, setIsToasterVisible] = useState(false)
  const [isOkButtonVisible, setOkButtonVisibility] = useState(false)
  const [statusMessage, setStatusMessage] = useState({ messageType: '', message: '' })
  const [areButtonsEnabled, enableButtons] = useState({ confirm: true, cancel: true })

  const setErrorMessage = (message) => setStatusMessage({ messageType: 'error', message })
  const setEmptyStatusMessage = () => setStatusMessage({ messageType: '', message: '' })
  const modalStrings = localizedStrings.ModalStrings

  const handleConfirmClick = async (event) => {
    event.preventDefault()
    setEmptyStatusMessage()
    enableButtons({ confirm: false, cancel: false })
    setIsToasterVisible(true)
    try {
      const result = await pm.handleRequestCardExchange(companyCard)
      handleOnConfirmResponse(result)
    } catch (error) {
      handleError(error.message)
    }
    setIsToasterVisible(false)
  }

  const handleOnConfirmResponse = ({ statusCode, message }) => {
    if (statusCode === 200) {
      setStatusMessage({ messageType: 'success', message })
      enableButtons({ confirm: true, cancel: true })
      setOkButtonVisibility(true)
    } else {
      handleError(message)
    }
  }

  const handleError = (message) => {
    setErrorMessage(message)
    enableButtons({ confirm: false, cancel: true })
  }

  useEffect(() => {
    setEmptyStatusMessage()
    enableButtons({ confirm: true, cancel: true })
  }, [isOpen])

  const id = 'exchange-card-modal'

  const headerComponent = (
    <StyledFlexColumn>
      <StyledWarning><i /></StyledWarning>
      <StyledModalHeading className='modal-title' id={`${id}-label`}>{title}</StyledModalHeading>
    </StyledFlexColumn>
  )

  const footer = !isOkButtonVisible ? (
    <>
      <ScaniaButton type='danger' disabled={!areButtonsEnabled.confirm} onClick={handleConfirmClick}>
        {localizedStrings.ModalStrings.RequestCardYes}
      </ScaniaButton>
      <ScaniaButton type='secondaryOutline' disabled={!areButtonsEnabled.cancel} onClick={(event) => { onClose(event, statusMessage) }}>
        {localizedStrings.ModalStrings.Cancel}
      </ScaniaButton>
    </>
  ) : (
    <ScaniaButton type='primary' onClick={(event) => { onClose(event, statusMessage) }}>
      {modalStrings.OkValue}
    </ScaniaButton>
  )

  return (
    <StyledModal
      show={isOpen}
      onHide={(event) => { onClose(event, statusMessage) }}
      onExited={() => setOkButtonVisibility(false)}
      backdrop='static'
      className={BOOTSTRAP_SCOPE_CLASS}
    >
      <StyledModalHeader>
        {headerComponent}
      </StyledModalHeader>

      <Modal.Body>
        <p>{modalStrings.ExchangeCompanyCardMessage}</p>
        <p><b>{modalStrings.CompanyName} </b>: {companyCard.CustomerName}<br />
          <b>{modalStrings.PrintedCardNumber}</b>: {companyCard.PrintedCompanyCardNumber}<br />
          <b>{modalStrings.ChipNumber} </b>: {companyCard.ChipNumber}<br /><br />
          {modalStrings.ReturnAddress}
        </p>
        <p>{modalStrings.CannotBeUndone}</p>
        <StyledToasterWrapper size='30px'>
          <Toaster show={isToasterVisible} size='30px' />
        </StyledToasterWrapper>
        <StyledMessage messageType={statusMessage.messageType}>{statusMessage.message}</StyledMessage>
      </Modal.Body>
      <StyledModalFooter className={isOkButtonVisible ? 'ok-footer' : 'confirm-cancel-footer'}>
        {footer}
      </StyledModalFooter>
    </StyledModal>
  )
}

export default ExchangeCardModal
