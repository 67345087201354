import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import StyledModal from './Modal.css'
import CloseButton from './CloseButton'

const Modal = ({ onClose, title, children, className = '', backgroundClose = true, footer = true, modalRoot, ...props }) => {
  const [fadeType, setfadeType] = useState(null)

  useEffect(() => {
    setfadeType('in')
    window.addEventListener('keydown', onEscKeyDown, false)

    return () => {
      window.removeEventListener('keydown', onEscKeyDown, false)
    }
  }, []
  )

  const transitionEnd = (event) => {
    if (event.propertyName === 'opacity' && fadeType === 'out') {
      onClose()
    }
  }

  const onEscKeyDown = (event) => {
    if (event.key === 'Escape') setfadeType('out')
  }

  const handleClick = e => {
    e.preventDefault()
    setfadeType('out')
  }

  return ReactDOM.createPortal(
    <StyledModal
      {...props}
      className={`wrapper fade-${fadeType} ${className}`}
      role='dialog'
      onTransitionEnd={transitionEnd}
    >
      <div className='box-dialog'>
        <div className='box-header'>
          <h4 className='box-title'>{title}</h4>
          <CloseButton onClick={handleClick} />
        </div>
        <div className='box-content'>
          {children}
        </div>
        {
          footer &&
            <div className='box-footer'>
              <button onClick={handleClick} className='close'>Close</button>
            </div>
        }
      </div>
      <div
        className='background'
        data-testid='background'
        onMouseDown={backgroundClose ? handleClick : undefined}
      />
    </StyledModal>,
    modalRoot)
}

export default Modal
