import React from 'react'
import styled, { ThemeProvider } from 'styled-components'

const _scaniaBlue = 'rgb(3, 58, 106)'
const _primaryBoxShadow = 'inset 0 0 0 99999px rgba(255, 255, 255, 0.2)'

const defaultTheme = {
  backgroundColor: {
    primary: _scaniaBlue,
    danger: 'rgb(220, 53, 69)',
    secondaryOutline: 'white'
  },
  buttonBorder: {
    primary: _scaniaBlue,
    secondaryOutline: _scaniaBlue,
    danger: 'rgb(220, 53, 69)'
  },
  buttonColor: {
    primary: 'rgb(250, 250, 250)',
    secondaryOutline: _scaniaBlue,
    danger: 'white'
  },
  buttonBoxShadow: {
    primary: _primaryBoxShadow,
    secondaryOutline: 'inset 0 0 0 99999px rgba(0, 0, 0, 0.1)',
    danger: _primaryBoxShadow
  }
}

/**
 * Classname that should be applied to all elements that needs the Bootstrap global styles (in case the BootstrapRestrictor component is used)
 * If one of the elements ancestors already has this class applyed, it is not needed to add it again.
 * This should typically be added to a root element, and to elements that are appended outside that root, for example modal overlays.
 */
const BOOTSTRAP_SCOPE_CLASS = 'bootstrap-scope'

/**
 * Bootstrap styles affect the entire page, even outside the react app.
 * This component tries to cancel that effect, and scope bootstrap to the given root element.
 * Note that it is not really possible to cancel styles this way, and Admin portal specific css is re-introduced below.
 * Thus, this component cannot be used outside the admin portal at the moment.
 * A better way would be to use css modules, but react-bootstrap does not support that.
 *
 * @param {Object} props
 * @param {DomElement} props.scopeClass - Bootstrap global styles will be scoped to elements with this class (as good as possible).
 */

const BootstrapRestrictor = ({ scopeClass = BOOTSTRAP_SCOPE_CLASS }) => (
  <style>
    {`
        /* admin portal styles, overriding global styles from Bootstrap */
        *, :after, :before {
            box-sizing: content-box;
            line-height: initial;
        }
        body {
            font-family: Tahoma, Geneva, Verdana, Arial, Helvetica, sans-serif;
        }
        a {
            text-decoration: underline;
        }

        /* Bootstrap styles */
        body *.${scopeClass}, 
        body *.${scopeClass}:after, 
        body *.${scopeClass}:before, 
        body *.${scopeClass} *, 
        body *.${scopeClass} :after, 
        body *.${scopeClass} :before {
            box-sizing: border-box;
            line-height: 1.5;
        }
        body .${scopeClass}, body .${scopeClass} * {
            font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        }

        a.${scopeClass}, a .${scopeClass} {
            text-decoration: none;
        }
    `}
  </style>
)

const ScaniaButton = styled.button`
    background-color: ${({ theme, type }) => theme.backgroundColor[type]};
    border: ${({ theme, type }) => `1px solid ${theme.buttonBorder[type]}`};
    border-radius: 3px;
    padding: 9px 18px 8px;
    color: ${({ theme, type }) => theme.buttonColor[type]};
    font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    &:hover:not(:disabled), &:focus:not(:disabled)         {
        border-color: rgb(0, 98, 204);
        box-shadow: ${({ theme, type }) => theme.buttonBoxShadow[type]};
    }
    &:disabled {
        opacity: .65;
        cursor: auto;
    }
`

ScaniaButton.defaultProps = {
  theme: defaultTheme
}

/**
 * Provides a default Corporate-ui like theme to all it's children
 *
 * This is an adaptation of corporate-ui for styled-components.
 * Note that styles have been included on an as-needed basis. So far it's a very limited subset of corporate-ui styles.
 */
const DefaultThemeProvider = ({ theme = defaultTheme, children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>

export { DefaultThemeProvider, defaultTheme, ScaniaButton, BootstrapRestrictor, BOOTSTRAP_SCOPE_CLASS }
